// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-layout-js": () => import("./../../../src/components/blog/blog-layout.js" /* webpackChunkName: "component---src-components-blog-blog-layout-js" */),
  "component---src-components-blog-blog-tag-js": () => import("./../../../src/components/blog/blog-tag.js" /* webpackChunkName: "component---src-components-blog-blog-tag-js" */),
  "component---src-components-blog-blog-tags-js": () => import("./../../../src/components/blog/blog-tags.js" /* webpackChunkName: "component---src-components-blog-blog-tags-js" */),
  "component---src-components-notes-note-js": () => import("./../../../src/components/notes/note.js" /* webpackChunkName: "component---src-components-notes-note-js" */),
  "component---src-components-poems-poem-layout-js": () => import("./../../../src/components/poems/poem-layout.js" /* webpackChunkName: "component---src-components-poems-poem-layout-js" */),
  "component---src-components-poems-poem-tag-js": () => import("./../../../src/components/poems/poem-tag.js" /* webpackChunkName: "component---src-components-poems-poem-tag-js" */),
  "component---src-components-poems-poem-tags-js": () => import("./../../../src/components/poems/poem-tags.js" /* webpackChunkName: "component---src-components-poems-poem-tags-js" */),
  "component---src-components-posts-post-js": () => import("./../../../src/components/posts/post.js" /* webpackChunkName: "component---src-components-posts-post-js" */),
  "component---src-components-posts-post-tag-js": () => import("./../../../src/components/posts/post-tag.js" /* webpackChunkName: "component---src-components-posts-post-tag-js" */),
  "component---src-components-posts-post-tags-js": () => import("./../../../src/components/posts/post-tags.js" /* webpackChunkName: "component---src-components-posts-post-tags-js" */),
  "component---src-components-tags-tag-js": () => import("./../../../src/components/tags/tag.js" /* webpackChunkName: "component---src-components-tags-tag-js" */),
  "component---src-components-tags-tags-js": () => import("./../../../src/components/tags/tags.js" /* webpackChunkName: "component---src-components-tags-tags-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-articles-all-js": () => import("./../../../src/pages/articles/all.js" /* webpackChunkName: "component---src-pages-articles-all-js" */),
  "component---src-pages-articles-index-mdx": () => import("./../../../src/pages/articles/index.mdx" /* webpackChunkName: "component---src-pages-articles-index-mdx" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-canon-js": () => import("./../../../src/pages/canon.js" /* webpackChunkName: "component---src-pages-canon-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-mileage-metrics-mdx": () => import("./../../../src/pages/mileage-metrics.mdx" /* webpackChunkName: "component---src-pages-mileage-metrics-mdx" */),
  "component---src-pages-notes-404-mdx": () => import("./../../../src/pages/notes/404.mdx" /* webpackChunkName: "component---src-pages-notes-404-mdx" */),
  "component---src-pages-notes-index-js": () => import("./../../../src/pages/notes/index.js" /* webpackChunkName: "component---src-pages-notes-index-js" */),
  "component---src-pages-poetry-all-js": () => import("./../../../src/pages/poetry/all.js" /* webpackChunkName: "component---src-pages-poetry-all-js" */),
  "component---src-pages-poetry-index-mdx": () => import("./../../../src/pages/poetry/index.mdx" /* webpackChunkName: "component---src-pages-poetry-index-mdx" */),
  "component---src-pages-poetry-seasons-of-thought-mdx": () => import("./../../../src/pages/poetry/seasons-of-thought.mdx" /* webpackChunkName: "component---src-pages-poetry-seasons-of-thought-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */)
}

